import MainDiv from "./components/MainDiv";
import Layout from './components/Layout';
import Info from './components/Info'
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Gallery from './components/Gallery';
import Hours from './components/Hours';
import YandexMap from './components/YandexMap';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import './App.css';


const App = () => (
        <div className="App">
            <MainDiv>
                <Layout/>
                <Info/>
            </MainDiv>
            <div className="black-bg">
                <MainDiv>
                    <AboutUs/>
                </MainDiv>
            </div>
            <MainDiv>
                <Services/>
            </MainDiv>
            <div className="black-bg">
                <MainDiv>
                    <Gallery/>
                </MainDiv>
            </div>
            <MainDiv>
                <Hours/>
            </MainDiv>
                {/*<YandexMap/>*/}
            <div className="black-bg">
                <MainDiv>
                    <ContactUs/>
                    <Footer/>
                </MainDiv>
            </div>
        </div>
            )
            ;

            export default App;