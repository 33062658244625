import React from 'react';
import './AboutUs.css';

const AboutUs = () => (
    <section className="about-us">
        <div className="text">
            <h2>About us</h2>
            <p>At our firm in Kaliningrad, KGD, we specialize in installation services. We take pride in our work and prioritize your needs.</p>
            <p>Our team delivers quality installations with precision and care. Trust us to handle your projects seamlessly.</p>
        </div>
        <div className="about-us_image">
            <img src="/images/about-us.jpg" alt=""/>
        </div>
    </section>
);

export default AboutUs;