import ScrollReveal from 'scrollreveal';
import {useEffect, useRef} from "react";

const MainDiv = ({ children }) => {
    // Создаем реф для контейнера
    const containerRef = useRef(null);

    // Используем хук useEffect, чтобы инициализировать анимацию
    useEffect(() => {
        // Проверяем, что контейнер выбран правильно
        if (containerRef.current) {
            ScrollReveal().reveal(containerRef.current, {
                distance: '200px',
                duration: 1000,
                easing: 'ease-in-out',
                origin: 'bottom',
                interval: 200, // Интервал между анимациями элементов
                reset: false // Повторная активация анимации при скролле обратно
            });
        }
    }, []);

    return (
        <div ref={containerRef} className="container MainDiv reveal">
            {children}
        </div>
    );
};

export default MainDiv;