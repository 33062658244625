import React, { useState } from 'react';
import './ContactUs.css';
import axios from "axios";

const ContactUs = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [responseMessage, setResponseMessage] = useState('');
    const [errors, setErrors] = useState({ name: false, email: false, message: false });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: false }); // Убираем ошибку при вводе
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Валидация
        const newErrors = {
            name: !formData.name,
            email: !formData.email,
            message: !formData.message,
        };

        if (newErrors.name || newErrors.email || newErrors.message) {
            setErrors(newErrors);
            return; // Останавливаем выполнение, если есть ошибки
        }

        try {
            const response = await axios.post('http://localhost:8080/api/contact/send', formData);
            setResponseMessage('Email sent successfully');
            alert('Email sent successfully');
        } catch (error) {
            setResponseMessage('Failed to send email');
            alert('Failed to send email');
        }
    };

    return (
        <section className="contact-us" id='contact-us'>
            <form onSubmit={handleSubmit}>
                <div className='contact_item'>
                    <label>
                        <p>Name:</p>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={errors.name ? 'error' : ''}
                        />
                    </label>
                    <label>
                        <p>Email:</p>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={errors.email ? 'error' : ''}
                        />
                    </label>
                </div>
                <div className='contact_item'>
                    <label>
                        <p>Message:</p>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className={errors.message ? 'error' : ''}
                        />
                    </label>
                </div>
                <button className="button" type="submit">Send it</button>
            </form>
            <div className="response-message">{responseMessage}</div>
            <div className="contact-us_text">
                <h2>Contact Our Team</h2>
                <p><span>Number:</span> 8 (911) 999 99-99</p>
                {/*<p><span>Adress:</span> 236022 г.Калининград, ул.Советский пр-т д. 43, пом. XXI, подвал №1.</p>*/}
            </div>
        </section>
    );
};

export default ContactUs;