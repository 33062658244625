import React from 'react';
import './Hours.css';

const HourRow = ({ day, hours }) => (
    <div className='hours_row'>
        <tr>
            <span>
                <td>{day}</td>
            </span>
            <td>{hours}</td>
        </tr>
    </div>
);

const Hours = () => (
    <section className="hours">
        <h2>Hours</h2>
        <table>
            <tbody>
            <HourRow day="Monday" hours="9:00 - 20:00" />
            <HourRow day="Tuesday" hours="9:00 - 20:00" />
            <HourRow day="Wednesday" hours="9:00 - 20:00" />
            <HourRow day="Thursday" hours="9:00 - 20:00" />
            <HourRow day="Friday" hours="9:00 - 20:00" />
            <HourRow day="Saturday" hours="Closed" />
            <HourRow day="Sunday" hours="Closed" />
            </tbody>
        </table>
    </section>
);

export default Hours;