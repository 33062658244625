import React from 'react';
import {Image} from 'react-native';
import './Services.css';
import {View} from "react-native-web";

const services = [
    "Home Theater Setup", "Smart Home Installation", "Security System Installation",
    "Network Configuration", "Appliance Installation", "Lighting Installation"
];

const images = [
    {
        src: '/images/services1.jpg',
        alt: 'service1'
    },
    {
        src: '/images/services2.jpg',
        alt: 'service2'
    },
    {
        src: '/images/gallery5.jpg',
        alt: 'service3'
    },
    {
        src: '/images/services4.jpg',
        alt: 'service4'
    },
    {
        src: '/images/services5.jpg',
        alt: 'service5'
    },
    {
        src: '/images/services6.jpg',
        alt: 'service6'
    },
]

const Services = () => (
    <section className="services">
        <h2>Introducing Our Services</h2>
        <div className="services_list">
            {images.map((image, index) => (
                <div key={index} className="services_item">
                    <img className='services_img' src={image.src} alt={image.alt}/>
                    <p>{services.at(index)}</p>
                </div>
            ))}
        </div>
    </section>
);

export default Services;