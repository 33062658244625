import React from 'react';
import './Layout.css';

const Layout = () => (
    <header className="layout">
        <div className="logo_container">
            <div className="logo">
                <img src="/images/icons800.png" alt="logo"/>
            </div>
            <h1>OOO "Арсенал Систем"</h1>
        </div>
    </header>
);

export default Layout;