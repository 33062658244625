import React from 'react';
import './Info.css';

const Info = () => {
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <section className="info">
            <div className="text">
                <h2>Install and Stay Connected</h2>
                <p>Hire skilled professionals to set up your essential systems in Kaliningrad. Efficient service every time.</p>
                <button className='button' onClick={() => scrollToSection('contact-us')}>Contact Us</button>
            </div>
            <div className="info_image">
                <img src="/images/info.jpg" alt=""/>
            </div>
        </section>
    );
}

export default Info;