import React from 'react';
import './Gallery.css';
import ImageGallery from "react-image-gallery";

const images = [
    {
        src: '/images/gallery4.jpg',
        alt: 'gallery1'
    },
    {
        src: '/images/gallery1.jpg',
        alt: 'gallery2'
    },
    {
        src: '/images/gallery2.jpg',
        alt: 'gallery3'
    },
    {
        src: '/images/services3.jpg',
        alt: 'gallery4'
    },
    {
        src: '/images/gallery3.jpg',
        alt: 'gallery5'
    },
]


const Gallery = () => {
    return (
        <section className="gallery">
            <h1>Gallery</h1>
            <div className="gallery-grid">
                {images.map((image, index) => (
                    <div key={index} className="gallery-item">
                        <img src={image.src} alt={image.alt} />
                    </div>
                ))}
            </div>
        </section>
    );
};


export default Gallery;